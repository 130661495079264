<template>
  <mobile-screen
    :header="true"
    :footer="false"
    screen-class="icon-app1 outlook-screen gray-bg"
    v-if="recurrence"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="outlook-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_outlook'
            }"
          >
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "outlook",
              "recurrence",
              `occurs-${recurrence.recurrenceType}`
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section tiny-top-spacing recurrence-wrapper">
      <li class="clebex-item-section-item">
        <div class="clebex-pill-link">
          <div class="clebex-item-content-wrapper">
            <span class="label larger-label">
              <div>
                {{ displayLabelName("outlook", "recurrence", "recurrence") }}
              </div>
            </span>
          </div>
        </div>
      </li>
      <li class="clebex-item-section-item no-separator recurrence-selection">
        <div class="clebex-item-content-wrapper">
          <div @click="setRecurrenceType('daily')" class="recurrence-period">
            <div class="recurrence-period-title">
              {{ displayLabelName("outlook", "recurrence", "daily") }}
            </div>
            <icon
              v-if="recurrence.recurrenceType === 'daily'"
              icon="#cx-app1-checkmark"
              width="20"
              height="20"
            />
          </div>
          <div @click="setRecurrenceType('weekly')" class="recurrence-period">
            <div class="recurrence-period-title">
              {{ displayLabelName("outlook", "recurrence", "weekly") }}
            </div>
            <icon
              v-if="recurrence.recurrenceType === 'weekly'"
              icon="#cx-app1-checkmark"
              width="20"
              height="20"
            />
          </div>
        </div>
        <div class="clebex-item-content-wrapper">
          <div @click="setRecurrenceType('monthly')" class="recurrence-period">
            <div class="recurrence-period-title">
              {{ displayLabelName("outlook", "recurrence", "monthly") }}
            </div>
            <icon
              v-if="recurrence.recurrenceType === 'monthly'"
              icon="#cx-app1-checkmark"
              width="20"
              height="20"
            />
          </div>
          <div @click="setRecurrenceType('yearly')" class="recurrence-period">
            <div class="recurrence-period-title">
              {{ displayLabelName("outlook", "recurrence", "yearly") }}
            </div>
            <icon
              v-if="recurrence.recurrenceType === 'yearly'"
              icon="#cx-app1-checkmark"
              width="20"
              height="20"
            />
          </div>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section tiny-top-spacing start-end-date">
      <li
        @click="showDatePickerStart = !showDatePickerStart"
        class="clebex-item-section-item full-right-underline cursor-pointer"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label"
              >{{ displayLabelName("outlook", "recurrence", "start-date") }}
            </label>
            <label class="clebex-section-input-label date"
              >{{ displayDate(start) }}
            </label>
          </div>
        </div>
      </li>
      <li
        v-if="showDatePickerStart"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <select-date
              :show-date-picker="true"
              :selectDateFn="setStartDate"
              date-format="YYYY-MM-DD"
              :display-date-format="globalDateFormat"
              :selected-date="calendarStart"
            />
          </div>
        </div>
      </li>
    </ul>
    <ul
      v-if="recurrence.recurrenceType !== 'yearly'"
      class="clebex-item-section tiny-top-spacing capacity-wrapper"
    >
      <li class="clebex-item-section-item full-right-underline cursor-pointer">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label"
              >{{ displayLabelName("outlook", "recurrence", "repeat-every") }}
            </label>
            <input type="text" :value="repeat + ' ' + repeatLabel" />
          </div>
          <span class="follow-up-icons">
            <span
              @click="repeat > 1 ? repeat-- : (repeat = repeat)"
              class="follow-up-icon-item"
            >
              -
            </span>
            <span @click="repeat++" class="follow-up-icon-item">
              +
            </span>
          </span>
        </div>
      </li>
    </ul>
    <ul
      v-if="recurrence.recurrenceType === 'weekly'"
      class="clebex-item-section tiny-top-spacing"
    >
      <li class="clebex-item-section-item full-right-underline">
        <div class="clebex-item-content-wrapper">
          <ul class="checkbox-day-list" v-if="dayLabels">
            <li
              class="checkbox-wrapper"
              v-for="(dayLabel, i) in dayLabels"
              :key="i"
            >
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  :id="`day${i}`"
                  :name="dayLabel"
                  v-model="days[i]"
                />
                <label :for="`day${i}`" class="day-of-the-week">
                  <icon icon="#cx-app1-checkmark" />
                  {{ dayLabel }}</label
                >
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <ul class="clebex-item-section tiny-top-spacing start-end-date">
      <li
        @click="showDatePickerEnd = !showDatePickerEnd"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label"
              >{{ displayLabelName("outlook", "recurrence", "end-date") }}
            </label>
            <label class="clebex-section-input-label date"
              >{{ displayDate(endDate) }}
            </label>
          </div>
        </div>
      </li>
      <li
        v-if="showDatePickerEnd"
        class="clebex-item-section-item full-right-underline"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <select-date
              :show-date-picker="true"
              :selectDateFn="setEndDate"
              date-format="YYYY-MM-DD"
              :display-date-format="globalDateFormat"
              :selected-date="endDate"
            />
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapGetters, mapActions } from "vuex";
import { DateTime } from "luxon";
import { formatDate } from "@/services/helpers";
import { getLang } from "@/services/http-service";

export default {
  name: "Recurrence",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDate: defineAsyncComponent(() =>
      import("@/components/global/SelectDate")
    )
  },
  data() {
    return {
      showDatePickerStart: false,
      showDatePickerEnd: false,
      repeat: 1,
      days: [true, true, true, true, true, true, true],
      dayLabels: null,
      endYear: parseInt(
        DateTime.fromISO(this.start)
          .plus({ days: 180 })
          .toFormat("yyyy")
      ),
      endMonth:
        parseInt(
          DateTime.fromISO(this.start)
            .plus({ days: 180 })
            .toFormat("M")
        ) - 1,
      endDay: parseInt(
        DateTime.fromISO(this.start)
          .plus({ days: 180 })
          .toFormat("d")
      )
    };
  },
  created() {
    this.setDayLabels();
    this.setDays();
    this.setEndDate();
    this.setCustomRecurrence(false, true);
  },
  computed: {
    ...mapState("outlook", ["start", "end", "recurrence"]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    endDate() {
      if (
        this.recurrence &&
        this.recurrence.seriesTime &&
        this.recurrence.seriesTime.endYear &&
        this.recurrence.seriesTime.endMonth &&
        this.recurrence.seriesTime.endDay
      ) {
        return `${this.recurrence.seriesTime.endYear}-${
          this.recurrence.seriesTime.endMonth.toString().length === 1 ? "0" : ""
        }${this.recurrence.seriesTime.endMonth}-${
          this.recurrence.seriesTime.endDay.toString().length === 1 ? "0" : ""
        }${this.recurrence.seriesTime.endDay}`;
      }
      return null;
    },
    calendarStart() {
      if (this.start) {
        return DateTime.fromISO(this.start).toFormat("yyyy-MM-dd");
      }
      return null;
    },
    calendarEnd() {
      if (this.end) {
        return DateTime.fromISO(this.end).toFormat("yyyy-MM-dd");
      }
      return null;
    },
    repeatLabel() {
      let label = this.displayLabelName("outlook", "recurrence", "day-s");
      switch (this.recurrence.recurrenceType) {
        case "daily":
          label = this.displayLabelName("outlook", "recurrence", "day-s");
          break;
        case "weekly":
          label = this.displayLabelName("outlook", "recurrence", "week-s");
          break;
        case "monthly":
          label = this.displayLabelName("outlook", "recurrence", "month-s");
          break;
        case "yearly":
          label = this.displayLabelName("outlook", "recurrence", "year-s");
          break;

        default:
          break;
      }
      return label;
    },
    daysOfTheWeek() {
      let days = [];
      this.days.forEach((element, i) => {
        if (element) {
          switch (i) {
            case 0:
              days.push("mon");
              break;
            case 1:
              days.push("tue");
              break;
            case 2:
              days.push("wed");
              break;
            case 3:
              days.push("thu");
              break;
            case 4:
              days.push("fri");
              break;
            case 5:
              days.push("sat");
              break;
            case 6:
              days.push("sun");
              break;
            default:
              break;
          }
        }
      });
      return days;
    }
  },
  methods: {
    ...mapActions("outlook", ["setStart", "setEnd", "setRecurrence"]),
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(DateTime.fromISO(date), this.globalDateFormat);
    },
    setRecurrenceType(type) {
      this.recurrence.recurrenceType = type;

      this.recurrence.seriesTime.startMonth =
        parseInt(DateTime.fromISO(this.start).toFormat("M")) - 1;
      this.recurrence.seriesTime.startTimeMinutes =
        DateTime.fromISO(this.start)
          .diff(DateTime.fromISO(this.start).startOf("day"), "minutes")
          .as("minutes") - 120;
      this.setCustomRecurrence(type);
    },
    postRecurrence() {
      parent.postMessage(
        {
          call: "recurrence",
          value: JSON.parse(JSON.stringify(this.recurrence))
        },
        "*"
      );
    },
    setDayLabels() {
      const locale = getLang();
      this.dayLabels = [
        DateTime.now()
          .set({ weekday: 1 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 2 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 3 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 4 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 5 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 6 })
          .toFormat("ccc", { locale: locale }),
        DateTime.now()
          .set({ weekday: 7 })
          .toFormat("ccc", { locale: locale })
      ];
    },
    setCustomRecurrence(type, initial) {
      if (!type) {
        if (this.recurrence && this.recurrence.recurrenceType) {
          type = this.recurrence.recurrenceType;
        } else {
          type = "daily";
        }
      }
      // set default daily recurrence here
      let recurrenceObject = {
        recurrenceProperties: {
          days: [],
          interval: this.repeat ? this.repeat : 1,
          firstDayOfWeek: "sun"
        },
        recurrenceType: type,
        seriesTime: {
          startYear: parseInt(DateTime.fromISO(this.start).toFormat("yyyy")),
          startMonth: parseInt(DateTime.fromISO(this.start).toFormat("M")) - 1,
          startDay: parseInt(DateTime.fromISO(this.start).toFormat("d")),
          endYear: this.endYear,
          endMonth: this.endMonth,
          endDay: this.endDay,
          startTimeMinutes:
            DateTime.fromISO(this.start)
              .diff(DateTime.fromISO(this.start).startOf("day"), "minutes")
              .as("minutes") - 120,
          durationMinutes: DateTime.fromISO(this.end)
            .diff(DateTime.fromISO(this.start), "minutes")
            .as("minutes")
        }
      };
      switch (type) {
        case "weekly":
          recurrenceObject.recurrenceProperties.days = this.daysOfTheWeek;
          break;
        case "monthly":
          recurrenceObject.recurrenceProperties.dayOfMonth = parseInt(
            DateTime.fromISO(this.start).toFormat("d")
          );
          break;
        case "yearly":
          recurrenceObject.recurrenceProperties.dayOfMonth = parseInt(
            DateTime.fromISO(this.start).toFormat("d")
          );
          recurrenceObject.recurrenceProperties.month = DateTime.fromISO(
            this.start
          )
            .toFormat("LLL")
            .toLowerCase();
          break;

        default:
          break;
      }
      if (
        initial &&
        this.recurrence &&
        this.recurrence.recurrenceProperties &&
        this.recurrence.recurrenceProperties.interval
      ) {
        recurrenceObject.recurrenceProperties.interval = this.recurrence.recurrenceProperties.interval;
      }
      this.setRecurrence(recurrenceObject);
      this.postRecurrence();
    },
    setDays() {
      if (this.recurrence && this.recurrence.recurrenceType === "weekly") {
        let tempDays = [false, false, false, false, false, false, false];
        let changedDays = false;
        const setDaysLength = this.days.filter(el => el).length;
        this.recurrence.recurrenceProperties.days.forEach(element => {
          switch (element) {
            case "mon":
              tempDays[0] = true;
              if (!this.days[0]) {
                changedDays = true;
              }
              break;
            case "tue":
              tempDays[1] = true;
              if (!this.days[1]) {
                changedDays = true;
              }
              break;
            case "wed":
              tempDays[2] = true;
              if (!this.days[2]) {
                changedDays = true;
              }
              break;
            case "thu":
              tempDays[3] = true;
              if (!this.days[3]) {
                changedDays = true;
              }
              break;
            case "fri":
              tempDays[4] = true;
              if (!this.days[4]) {
                changedDays = true;
              }
              break;
            case "sat":
              tempDays[5] = true;
              if (!this.days[5]) {
                changedDays = true;
              }
              break;
            case "sun":
              tempDays[6] = true;
              if (!this.days[6]) {
                changedDays = true;
              }
              break;

            default:
              break;
          }
        });
        const setTempDaysLength = tempDays.filter(el => el === true).length;
        if (changedDays || setDaysLength !== setTempDaysLength) {
          this.days = tempDays;
        }
      }
    },
    setStartDate(value) {
      this.setStart(
        `${DateTime.fromISO(value).toISODate()}T${DateTime.fromISO(
          this.start
        ).toFormat("HH:mm")}`
      );
      this.postDateTime(this.start, "start");
    },
    setEndDate(value) {
      if (!value && !this.recurrence) {
        this.endYear = parseInt(
          DateTime.fromISO(this.start)
            .plus({ days: 180 })
            .toFormat("yyyy")
        );
        this.endMonth =
          parseInt(
            DateTime.fromISO(this.start)
              .plus({ days: 180 })
              .toFormat("M")
          ) - 1;
        this.endDay = parseInt(
          DateTime.fromISO(this.start)
            .plus({ days: 180 })
            .toFormat("d")
        );
      } else if (
        !value &&
        this.recurrence &&
        this.recurrence.seriesTime &&
        this.recurrence.seriesTime.endYear
      ) {
        this.endYear = this.recurrence.seriesTime.endYear;
        this.endMonth = this.recurrence.seriesTime.endMonth - 1;
        this.endDay = this.recurrence.seriesTime.endDay;
      } else if (value) {
        this.endYear = parseInt(DateTime.fromISO(value).toFormat("yyyy"));
        this.endMonth = parseInt(DateTime.fromISO(value).toFormat("M")) - 1;
        this.endDay = parseInt(DateTime.fromISO(value).toFormat("d"));
      }
      this.setCustomRecurrence(false);
    },
    postDateTime(date, type) {
      parent.postMessage(
        {
          call: type,
          value: DateTime.fromISO(date).toJSDate()
        },
        "*"
      );
    }
  },
  watch: {
    recurrence: {
      handler(val) {
        if (!val) {
          this.$router.push({ name: "r_outlook" });
        } else {
          this.repeat = val.recurrenceProperties.interval
            ? val.recurrenceProperties.interval
            : 1;
          this.setDays();
        }
      }
    },
    repeat: {
      handler() {
        // debounce for fast clicking repeat buttons
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setRecurrenceType(this.recurrence.recurrenceType);
        }, 300);
      }
    },
    days: {
      deep: true,
      handler() {
        // debounce for fast clicking repeat buttons
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setRecurrenceType(this.recurrence.recurrenceType);
        }, 600);
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: false
    }
  }
};
</script>
